.overflow-menu-container__trigger {
  border-radius: 3px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  cursor: pointer;

  @include transition(background-color);

  &:hover {
    background-color: $white;
  }

  &:focus {
    background-color: $mischka;
    outline: none;
  }

  svg path {
    @include transition(fill);
  }
}

.overflow-menu-container__trigger--active {
  background-color: $mischka;

  svg path {
    fill: $white;
  }
}

.overflow-menu {
  display: none;
  position: absolute;
  right: 0;
  background-color: $white;
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 220px;
  z-index: 10;
  border: 1px solid $mischka;

  &.menu--left {
    left: 0;
  }

  a {
    text-decoration: none;
    width: 100%;
    text-align: left;
    padding: 10px 20px;
  }

  .overflow-button {
    text-decoration: none;
    width: 100%;
    text-align: left;
    padding: 10px 20px;
    color: $deepCyan;
    font-weight: 600;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  li {
    min-height: 24px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: $cyan;
    cursor: pointer;

    @include transition(background-color);

    svg {
      width: 18px;
      margin-right: 21px;

      path {
        @include transition(fill);
      }
    }

    span {
      line-height: 1;
      font-size: 14px;
    }

    &:hover {
      color: $deep-cyan;
      background-color: $lightest-cyan;

      svg path {
        fill: $deep-cyan;
      }
    }
  }
}

.overflow-menu-container--dark {
  @extend .overflow-menu-container;

  .overflow-menu-container__trigger {
    &:hover {
      background-color: $heather;

      svg path {
        fill: $white;
      }
    }
  }
}

.overflow-menu--open {
  display: block !important;
}

.overflow-menu-container {
  position: relative;
  width: 45px;
  height: 25px;
}

.overflow-menu-container--right {
  float: right;
}

.overflow--loader {
  margin-left: 0.75em !important;
}
