$cyan: #00aec7;
$heather: #bbc4d1;
$deepCyan: #0f849e;
$teal: #00b398;
$blue: #004b87;
$white: #fff;
$lightCyan: #7fd1e3;
$paleSky: #646f80;
$mischka: #bbc4d1;
$mercury: #e6e6e6;
$mischka-alt: #d0d5dc;
$emperor: #a5a5a5;
$emperor--dark: #505050;
$black: #000;

$chart-color: #568a9e;

$select-border: rgba(34, 36, 38, 0.15);
$select-border--hover: rgba(34, 36, 38, 0.35);
$select-item--hover: rgba(0, 0, 0, 0.05);
$select-item--selected: rgba(0, 0, 0, 0.03);

// Colours
$mine-shaft: #333;
$silver: #bcbcbc;
$athens-grey: #eeeeef;
$light-grey: #d4d4d4;

$visitvic--blue: #0072ce;
$visitvic--dark-blue: #1f1546;
$visitvic--light-blue: #d9eaf8;
