.reports--container {
  background-color: white;
  padding: 0 0.75em;
}

.risk-monitor--data-row {
  padding-top: 0 !important;
}

.risk-monitor--info-icon {
  margin-left: 0.5em !important;
  font-size: 3em !important;
}

.reports--header-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.reports--header {
  color: $deepCyan;
  text-align: left;
  font-weight: 600;
  margin-bottom: 0.4rem;

  h6 {
    text-align: left;
  }
}

.last-updated {
  margin-left: 0.1rem;
}

.last-updated--lead {
  font-weight: 600;
}

.reports--header__logos {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .ui.image {
    width: 200px;
    height: 100%;
  }
}

.risk-monitor--footer__source {
  font-size: 0.75rem;
  padding-bottom: 2rem;

  p {
    margin-bottom: 0.375rem;

    span {
      font-weight: 600;
    }
  }
}

.reports--data-container {
  background-color: $athens-grey;
  padding: 1.25rem 0.5rem;
  height: 100%;

  &.ui.grid {
    align-items: flex-start;
  }

  &.ui.segment {
    background-color: $athens-grey;
  }
}

.reports--data-container__secondary {
  padding: 0;

  .risk-monitor--data-column__tabbed {
    border-left: 2px solid white;
    border-right: 2px solid white;
  }

  .risk-monitor--data-column__tabbed:first-child {
    border-left: none;
  }

  .risk-monitor--data-column__tabbed:last-child {
    border-right: none;
  }
}

.reports--data-container.reports--data-container__charts {
  padding: 1em !important;
}

.reports--data-container__state {
  padding-bottom: 0;
}

.data-container--row {
  flex-direction: column !important;

  h5:not(:first-child) {
    margin-top: 0.25rem;
    font-weight: 400;
  }
}

.risk-monitor--demographics-metrics-row {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.tabs {
  display: flex !important;
  flex-direction: column !important;

  .ui.tabular.menu {
    text-align: center !important;
    width: 100% !important;

    .item {
      flex: 1 1 auto;
      width: auto !important;
      background-color: rgba($deepCyan, 0.8) !important;
      border-radius: 0 8px 0 0 !important;
      color: $white !important;
      font-size: 1.0625rem;
      // font-size: 1rem;
      font-weight: 600 !important;
      padding: 1.125rem 0.5em !important;
      text-transform: uppercase !important;
      border: none !important;
      border-right: 4px solid $white !important;
      border-bottom: 4px solid white !important;
      z-index: 20;

      &:last-child {
        border-right: none !important;
      }

      &.active {
        border-radius: 0 8px 0 0 !important;
        background-color: $athens-grey !important;
        color: $deepCyan !important;
        border: none !important;
        border-right: 4px solid $white !important;
        border-bottom: 4px solid $athens-grey !important;
        opacity: 1;

        &:last-child {
          border-right: none !important;
        }
      }
    }
  }
}

.tabs__charts {
  .ui.tabular.menu {
    .item {
      &.active {
        background-color: $athens-grey;
        color: $deepCyan;
        border: none;
      }
    }
  }

  .ui.bottom.attached.segment.active.tab {
    border: none;
    border-radius: 0;
  }
}

@media (min-width: $small-monitor-min) {
  .reports--container {
    &.ui.grid > .row > .column {
      padding-left: 7.5px;
      padding-right: 7.5px;
    }

    &.ui.grid > .row > .column:first-child {
      padding-left: 15px;
    }

    &.ui.grid > .row > .column:last-child {
      padding-right: 15px;
    }

    &.ui.grid > .row:first-child {
      padding-bottom: 7.5px;
    }

    &.ui.grid > .row:last-child {
      padding-top: 7.5px;
    }
  }
}

.loading-indicator {
  height: 100%;
  display: flex;
  align-items: center;
}

.state-chart--value {
  text-anchor: middle;
}
