.dropdowns--wrapper {
  display: flex;
  flex-wrap: wrap;
}

.segments-dropdown--wrapper {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}

.metric-filters--dropdown {
  &.disabled {
    i::before {
      content: '\f023' !important;
      font-family: Icons, sans-serif;
    }
  }
}
