.mobile-menu__dropdown {
  .menu {
    .nav-menu__nav-link:last-of-type {
      margin-bottom: 0;
    }

    &:last-of-type {
      .nav-menu__nav-link:last-of-type {
        margin-bottom: 50px;
      }
    }
  }
}
