.dashboard-management--breadcrumb {
  padding-top: 10px !important;
}

.dashboard-form__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dashboard-form__submit {
  margin-right: 15px !important;
}

.dashboard-form__input {
  div {
    input {
      font-weight: 400 !important;
    }
  }

  textarea {
    font-weight: 400 !important;
  }
}

.dashboard-management-page-container {
  padding-bottom: 60px;
}
