.reports--container__recovery {
  h5,
  h6,
  .tabs .ui.tabular.menu .item {
    text-transform: none !important;
  }
}

.single-metric--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 33%;
  padding: 1.25rem 0.5rem;
  text-align: center;
  position: relative;

  .single-metric--primary-number {
    font-size: 3.25em;
    color: $deepCyan;
  }
}

.single-metric--title-wrapper {
  display: flex;
  height: 100%;
  align-items: center;
  margin-bottom: 0.5em;
}

.single-metric--updated-text {
  color: $emperor;
  font-size: 0.875em;
}

.key-metrics--header-wrapper {
  border-bottom: 2px solid $light-grey;
  margin: auto 2.5em;
  flex-direction: column !important;
}

.tabs__recovery {
  .age-score--score {
    color: $deepCyan !important;
  }

  .gender-score--score {
    color: $deepCyan !important;
  }
}

.chart--wrapper__recovery {
  padding: 0;
}

.small-sample--wrapper {
  position: absolute;
  background-color: #eeeeef;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0.9;
}

.small-sample--title {
  background-color: white;
  border: 1px solid $deepCyan;
  color: $deepCyan;
  border-radius: 3px;
  padding: 1em;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
    0 2px 10px 0 rgba(34, 36, 38, 0.15);
}

.line-chart--line-toggle.ui.checkbox {
  display: flex;
  padding: 0.5em 0.25em;
  width: 33.3%;
  // align-items: center;

  label.linechart--toggle-label {
    color: $emperor--dark;
    text-transform: capitalize;
    font-weight: 600;
    text-align: left;
    display: flex;
    align-items: center !important;
    // color: black !important;

    &::before {
      border-radius: 50%;
      width: 22px !important;
      height: 22px !important;
      left: 0;
      top: 5px;
    }

    &::after {
      content: '' !important;
      border-radius: 50%;
      width: 22px !important;
      height: 22px !important;
      left: 0;
      top: 5px;
      color: inherit !important;
      transform: scale(0.66);
    }
  }

  .linechart--toggle-label__consumer-confidence::after {
    background-color: #004b87;
  }

  .linechart--toggle-label__business-confidence::after {
    background-color: #0f849e;
  }

  .linechart--toggle-label__government-confidence::after {
    background-color: #00aec7;
  }

  .linechart--toggle-label__inflation-expectations::after {
    background-color: #505050;
  }

  .linechart--toggle-label__unemployment::after {
    background-color: #a5a5a5;
  }

  .linechart--toggle-label__under-employment::after {
    background-color: #bbc4d1;
  }
}

.filter-display--text {
  margin-top: 0;
  color: $deepCyan;
}
