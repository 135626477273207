.visitvic-header {
  display: none;
}

.visitvic--report-container {
  background-color: white;
  padding: 0 0.75em;
}

.visitvic--container {
  @media (min-width: $lg-max) {
    margin-bottom: 10px;

    &.ui.container {
      width: 1090px !important;
      max-width: 1090px !important;
    }
  }

  @media (min-width: 1200px) {
    &.ui.container {
      width: 1170px !important;
      max-width: 1170px !important;
    }
  }

  @media (min-width: 1300px) {
    &.ui.container {
      width: 1270px !important;
      max-width: 1270px !important;
    }
  }

  @media (min-width: 1400px) {
    &.ui.container {
      width: 1370px !important;
      max-width: 1370px !important;
    }
  }

  h5 {
    min-height: 42px;
  }

  h5,
  h6,
  .tabs .ui.tabular.menu .item {
    text-transform: none !important;
    color: $visitvic--blue;
  }

  .ui.dropdown.visitvic--dropdown-filter {
    font-size: 0.8em;
    line-height: 0.8em;
    font-weight: 500;
    border-radius: 0;
    border-color: $mine-shaft;

    .text {
      color: $mine-shaft !important;
      font-weight: 500;
    }

    .menu {
      border-left: 1px solid $mine-shaft !important;
      border-right: 1px solid $mine-shaft !important;
      border-bottom: 1px solid $mine-shaft !important;

      .item {
        width: 100%;
        padding-left: 1em !important;
        background-color: $white !important;

        span {
          margin: 0;
        }
      }
    }

    &.active,
    &:hover {
      border-color: $mine-shaft !important;
    }
  }

  .radar-chart--wrapper,
  .chart--wrapper {
    .radar-chart {
      .recharts-text {
        font-size: 0.7em;
      }
    }

    .chart--checkbox {
      cursor: pointer;
      padding: 0 0 1rem;

      span {
        padding-left: 23px;
        margin-right: 10px;
        font-weight: 400;
        font-size: 0.85em;
        color: #27251f;

        &:hover {
          color: #656565;
        }
      }

      label {
        &::before {
          border-radius: 0;
          width: 14px;
          height: 14px;
          left: 5px;
          top: 2px;
          border-color: inherit !important;
          background-color: inherit !important;
        }

        &::after {
          content: '' !important;
          border-radius: 0;
          width: 14px !important;
          height: 14px !important;
          left: 5px !important;
          top: 2px;
          color: inherit !important;
          background-color: inherit !important;
          border-color: inherit !important;
        }
      }
    }
  }

  .table--wrapper {
    padding: 1.25rem 0.5rem;

    .table--header {
      padding: 0 10px;
      text-align: left;
    }
  }

  .bar-chart--wrapper {
    overflow: hidden;

    .bar-chart {
      padding: 0 10px;

      .recharts-text {
        font-size: 0.7em;
        font-weight: bold;
        color: #27251f;
      }

      i {
        font-size: 1.2em;
        font-weight: bold;
      }

      .recharts-legend-item {
        .recharts-surface {
          top: -1px;
          position: relative;
        }
      }
    }
  }
}

.visit-vic__title-row {
  padding: 0 !important;

  .chart--wrapper {
    padding-bottom: 0 !important;
  }

  + .visit-vic__data-row {
    padding-top: 7.5px !important;
  }
}

.visit-vic__data-row--dual-charts {
  background-color: $athens-grey;

  > .column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.significance-legend {
  font-size: 12px;

  i {
    font-size: 20px;
    top: 3px;
    position: relative;
  }
}

.chart--checkbox__line {
  padding: 0 0.75em;
}

.line-chart {
  .recharts-legend-item {
    .recharts-surface {
      top: -1px;
      position: relative;
    }
  }
}

.visitvic--compare-table.ui.table {
  border: 0 !important;
  font-size: 0.75em !important;
  border-radius: 0 !important;

  tr {
    &:nth-child(2n-1) {
      background: $visitvic--light-blue;
    }

    td {
      border: 0;
      padding: 5px 10px;

      div {
        font-weight: bold;
      }
    }
  }
}

.radar-chart--visitvic {
  ul {
    display: flex;
    justify-content: center;

    li {
      display: flex !important;
      padding-left: 5px;
      font-weight: bold;
      font-size: 0.85em;
      margin-left: 0;
      margin-right: 5px !important;
    }
  }
}

.visitvic--header-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.visitvic--header {
  color: $visitvic--dark-blue;
  text-align: left;
  font-weight: 600;
  margin-bottom: 0.4rem;

  h6 {
    text-align: left;
  }
}

.visit-vic--info-icon {
  color: $visitvic--blue;
  position: relative;
  top: 3px;
  left: 3px;
}

.visitvic--dropdown-filter {
  width: 90%;
}

.visit-vic--info-icon_popup.popup {
  background: $visitvic--light-blue;
  border: 0;
  padding: 0.6em;
  box-shadow: none;

  &::before {
    background: $visitvic--light-blue !important;
    box-shadow: none !important;
  }
}

.visit-vic--metric-row {
  span {
    display: block;
    font-weight: bold;
    margin-left: 1em;
    margin-bottom: 2px;
    font-size: 0.8em;
  }
}

.gray-bg {
  background-color: $athens-grey;
}

@media (min-width: $small-monitor-min) {
  .reports-visitvic__container {
    &.ui.grid > .row > .column {
      padding-left: 7.5px;
      padding-right: 7.5px;

      &:last-child,
      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }

      &:nth-child(2) {
        padding-left: 7.5px;
        padding-right: 7.5px;
      }
    }
  }
}

.custom-tooltip__visit {
  background-color: $athens-grey !important;
  font-weight: 600;
  border: 1px solid $black;
}

.visit-tooltip--entry {
  display: flex;
  justify-content: space-between;
}

.visit-tooltip--key {
  color: $black;
}

.visit-tooltip--entry,
.visit-tooltip--value {
  color: $black;
}

.origin-table {
  tr {
    &:nth-child(4n),
    &:nth-child(4n - 1) {
      background: $visitvic--light-blue;
    }
  }
}

.origin-table--header {
  th {
    background: $visitvic--light-blue !important;
  }
}

.origin-table--origin {
  vertical-align: top;
}

.origin-table--destination {
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
}

.radar-chart__container {
  margin-top: -25px;
}

.bar-chart__svg > svg {
  overflow: visible;
}

.visitvic--routes {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #0072ce !important;

  .visitvic--link {
    display: flex;
    flex: 0 0 calc(100% / 6) !important;
    align-items: center;
    justify-content: center;
    padding: 1.125rem 1em !important;
    text-transform: none !important;
    text-decoration: none;
    background-color: #eeeeef !important;
    color: #505050 !important;
    font-size: 1rem;
    line-height: 1;
    font-weight: 600 !important;
    border: 0 !important;
    border-right: 1px solid #ddd !important;
    border-radius: 0 !important;

    &:hover {
      background-color: #ddd !important;
    }

    &.active {
      background-color: $visitvic--blue !important;
      color: $white !important;
      border-radius: 0 !important;
    }

    &:last-child {
      border-right: none !important;
    }
  }

  @media (max-width: $tablet-max) {
    flex-wrap: wrap;

    .visitvic--link {
      flex: 0 0 calc(100% / 3) !important;
      border-bottom: 1px solid #ddd !important;
    }
  }
}

.visitvic--footer-row {
  padding-top: 0 !important;
}

.visitvic--footer__source {
  font-size: 0.75rem;
  padding-bottom: 2rem;
  text-align: left;

  p {
    margin-bottom: 0.5em;
  }

  span {
    font-weight: 600;
  }
}

.reports-visitvic__container {
  .instruction-accordion-section {
    > .content {
      padding-left: 40px !important;

      ul {
        padding-left: 20px;

        &.no-padding {
          padding-left: 0;
        }

        li {
          padding: 0;
        }
      }

      .no-margin {
        margin-bottom: 0;
      }

      table {
        margin-bottom: 10px;
      }

      &:last-child {
        ul,
        li,
        p {
          margin-bottom: 0;
        }

        .left-padding {
          padding-left: 40px;
        }
      }
    }
  }
}

.reports-visitvic__container--instructions {
  h5 {
    text-align: left;
    color: inherit;
  }
}

.origin-chart--sig-diff-cell {
  position: relative;
}

.origin-chart--sig-diff {
  position: absolute;
  top: calc(50% - 0.9rem);
  right: 0;
}

.instructions__data-note {
  margin-bottom: 0.25em;
}
