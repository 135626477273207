.tableau__container-wrapper.ui.container {
  margin-top: 8em !important;
  margin-bottom: 8em !important;
  max-width: 1387px !important;
  width: calc(100% - 60px) !important;

  @media (min-width: 1200px) {
    // height: 918px !important;
  }
}

.tableau__container {
  // original styles
  // position: relative;
  // overflow: hidden;
  // width: 100% !important;
  // padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  // height: 918px;

  /* Then style the iframe to fit in the container div with full height and width */
  display: flex;
  justify-content: center;
}

.tableau__wrapper {
  overflow: auto;
  margin: 0 30px;
  // margin-left: 30px;
  // // overflow: auto;
  // position: relative;
  // left: 0;
}

iframe {
  border: none;
  margin: 4em auto 8em;
  position: static;
}
