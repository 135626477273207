.table-actions__right-column {
  display: flex !important;
  flex-flow: row wrap !important;
  align-items: center !important;
  justify-content: flex-end !important;

  @media (max-width: $sm-max) {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
  }
}

.table-actions__input {
  margin-right: 10px;

  @media (max-width: $sm-max) {
    margin-bottom: 10px;
  }
}

.table-actions__title {
  @extend .light-title;
}

.table-actions {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;

  @media (max-width: $sm-max) {
    margin: 0 !important;
  }

  @media (max-width: $md-max) {
    flex-direction: column !important;

    .column.column.column:not(.row) {
      padding: 10px !important;
      width: 100% !important;
    }
  }
}
