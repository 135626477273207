.side-menu-layout {
  background-color: $white;
  border: 1px solid $heather;
}

.side-menu-column {
  border-right: 1px solid $heather;
  background-color: $athens-grey;

  .side-menu {
    margin: -20px -15px;
  }
}

// stylelint-disable-next-line no-descending-specificity
.side-menu {
  background-color: $athens-grey;
  padding: 10px 0;
}

.side-menu__item {
  margin: 5px 10px;
}

.side-menu__item--active {
  background-color: $white;
  border-radius: 4px;

  .side-menu__item-label {
    font-weight: bold;
  }
}

.side-menu__link {
  display: flex;
  flex-flow: row nowrap;
  text-decoration: none;
  color: $black;
  font-weight: normal;
  border-radius: 4px;

  @include transition(background-color);

  padding: 15px 10px;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
    background-color: $white;
  }

  &.active {
    font-weight: bold;
  }
}

.side-menu__item-icon {
  flex: 0 0 25px;
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

// stylelint-disable-next-line no-descending-specificity
.side-menu__item-label {
  flex: 1 1 auto;
}

.side-menu__sub-menu {
  padding: 0 15px 10px 50px;

  h4 {
    font-size: 14px;
    font-weight: 200;
    color: $pale-sky;
    text-transform: uppercase;
    text-align: left;
  }
}

.side-menu__sub-menu-items {
  border-left: 2px solid $mischka;
  margin-bottom: 15px;
}

.side-menu__sub-menu-link {
  display: block;
  padding: 8px 20px;
  color: $pale-sky;
  font-weight: normal;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $pale-sky;
    text-decoration: none;
  }

  &:first-of-type {
    padding-top: 4px;
  }

  &:last-of-type {
    padding-bottom: 4px;
  }
}

.side-menu__sub-menu-link--active {
  font-weight: bold;
  color: $pale-sky;

  &:focus {
    color: $pale-sky;
  }
}

.side-menu-header__title {
  color: $black;
}

.additional-details__title {
  font-weight: 600;
}
